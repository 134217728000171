function animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        obj.innerHTML = Math.floor(progress * (end - start) + start).toLocaleString("de");
        if (progress < 1) {
            window.requestAnimationFrame(step)
        }
    };
    window.requestAnimationFrame(step);
}

export function updateLatestCaptisStats() {
    fetch("https://capt.is/resources/stats", {
        headers: {
            'key': 'n4g3063w567m956wkrw9e8f6z85et9zwj'
        }
    }).then(response => response.json())
      .then(v => {
          if (document.getElementById('captis--label--benutzer') != null) {
              document.getElementById("captis--label--mandanten").innerText = "Kunden";
              document.getElementById("captis--label--benutzer").innerText = "Benutzer";
              document.getElementById("captis--label--erfassungen").innerText = "Erfassungen";
              document.getElementById("captis--label--vorlagen").innerText = "Formulare";
              animateValue(document.getElementById("captis--mandanten"), 0, v.mandanten, 2000);
              animateValue(document.getElementById("captis--benutzer"), 0, v.benutzer, 2000);
              animateValue(document.getElementById("captis--erfassungen"), 0, v.erfassungenGesamt, 2000);
              animateValue(document.getElementById("captis--vorlagen"), 0, v.vorlagen, 2000);
              document.getElementById("captis--stats--container").style.display = 'flex';
          }
    })
}
